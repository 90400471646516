import type { SystemCssProperties } from '@mui/system'
import React from 'react'
import Avatar from '@mui/material/Avatar'
import PersonIcon from '@mui/icons-material/Person'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import ImageWithFallback from '../../ImageWithFallback'

type PlayerHeadshotAvatarProps = {
    imageUrl: string | null | undefined
    outlined?: boolean
    height?: number
    width?: number
    teamImageUrl?: string | null
    toolTip?: string | null
}

const PlayerHeadshotAvatar = ({
    imageUrl,
    outlined,
    height = 40,
    width = 40,
    teamImageUrl,
    toolTip,
}: PlayerHeadshotAvatarProps): JSX.Element => {
    const outlineStyles: SystemCssProperties = {
        backgroundColor: outlined ? 'common.white' : undefined,
        border: outlined ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
    }
    const avatarStyles: SystemCssProperties = {
        ...outlineStyles,
        displayPrint: 'none',
        width,
        height,
    }

    const playerAvatar = (
        <Tooltip title={toolTip}>
            <Avatar sx={avatarStyles}>
                <ImageWithFallback
                    width={width}
                    height={height}
                    alt="player headshot"
                    src={imageUrl}
                    priority
                    outlined={outlineStyles}
                >
                    <PersonIcon style={{ fill: outlined ? '#bdbdbd' : undefined }} />
                </ImageWithFallback>
            </Avatar>
        </Tooltip>
    )

    return (
        <Tooltip title={toolTip}>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <Avatar
                        sx={{
                            width: teamImageUrl ? width / 1.67 : 0,
                            height: teamImageUrl ? height / 1.67 : 0,
                            '.MuiAvatar-img': { objectFit: 'contain' },
                        }}
                        alt="team logo"
                        src={teamImageUrl || undefined}
                    />
                }
            >
                {playerAvatar}
            </Badge>
        </Tooltip>
    )
}

export default PlayerHeadshotAvatar
