import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'
import TableLoadingSkeleton from '../Table/LoadingSkeleton'

type WatchListWidgetPlayerViewLoadingSkeletonProps = {
    type: 'season' | 'game'
}

const WatchListWidgetPlayerViewLoadingSkeleton = ({
    type,
}: WatchListWidgetPlayerViewLoadingSkeletonProps): JSX.Element => (
    <Grid container padding={0}>
        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={1} alignItems="center">
                    {type === 'season' && <Skeleton variant="circular" width={30} height={30} />}
                    <Skeleton variant="rectangular" width={150} height={12} />
                </Box>
                <Box>
                    <Skeleton variant="rectangular" width={100} height={32} />
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
            <TableLoadingSkeleton
                config={{
                    fields: Array(9)
                        .fill(undefined)
                        .map((_, idx) => ({ skeletonStyle: { xs: 36 }, key: idx, label: '' })),
                    loadingSkeleton: { numOfRows: 1, height: 76 },
                }}
            />
        </Grid>
        {type === 'game' && (
            <Grid item xs={12} display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" position="relative" top={4}>
                    <Skeleton variant="rectangular" width={118} height={16} />
                </Box>
            </Grid>
        )}
        <Divider sx={{ marginTop: 2, width: '100%' }} />
    </Grid>
)

export default WatchListWidgetPlayerViewLoadingSkeleton
