import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type ErrorStateProps = {
    resourceName: string
}

const errorState = ({ resourceName }: ErrorStateProps): JSX.Element => (
    <Box>
        <Typography sx={{ fontWeight: 'medium' }} gutterBottom>
            An error occurred while loading {resourceName}
        </Typography>
        <Typography variant="body2">Refresh page to try again</Typography>
    </Box>
)
export default errorState
