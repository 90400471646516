import { rollup } from 'd3'
import { money } from './formatters'

export const formatLocsSalariesByYear = (locs: DTO.LOCProjections[] | undefined): Map<number, number> | undefined =>
    locs &&
    rollup(
        locs.filter((l) => l.type === 'Insights' && l.locSalaryEstimate !== null),
        ([{ locSalaryEstimate }]) => locSalaryEstimate as number,
        ({ season }) => season
    )

export const formatContractSalariesByYear = (
    salary: DTO.PlayerContract | undefined
): Record<number, DTO.PlayerContractSeason> | undefined =>
    salary?.seasons.reduce((acc: Record<number, DTO.PlayerContractSeason>, s) => ({ ...acc, [s.salaryYear]: s }), {})

export const formatYears = (season: number): number[] => new Array(4).fill(season).map((s: number, idx) => s + idx)
export const formatRegularSeasonLabel = (season: number | string): string => {
    let seasonAsNum
    if (typeof season === 'string') {
        if (season.includes('-')) return season
        seasonAsNum = Number(season)
    } else {
        seasonAsNum = season
    }
    return `${seasonAsNum}-${(seasonAsNum + 1).toString().substring(2)}`
}

export const salarySurplus = (
    contractYearSalary: number | null | undefined,
    contractYearLocSalary: number | null | undefined
): number => (contractYearSalary && contractYearLocSalary ? contractYearLocSalary - contractYearSalary : 0)

export const salarySurplusByContract = (
    contractSalariesByYear: Record<number, DTO.PlayerContractSeason | undefined>,
    locsSalariesByYear: Map<number, number>,
    years: number[]
): string => {
    const surplus = years.reduce(
        (acc, y) => acc + salarySurplus(contractSalariesByYear[y]?.salary, locsSalariesByYear.get(y)),
        0
    )
    return money(surplus, true, 1, true)
}
