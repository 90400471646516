import type { Except } from 'type-fest'
import React, { useRef, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Box from '@mui/material/Box'
import merge from 'lodash.merge'
import { useSearchParams } from 'next/navigation'
import { useWatchVariable } from '@/lib/hooks'

type ChartProps = Except<HighchartsReact.Props, 'ref' | 'highcharts'> & {
    isLoading: boolean
}

const Chart = ({ isLoading, options = {}, ...rest }: ChartProps): JSX.Element => {
    const chartRef = useRef<HighchartsReact.RefObject>(null)

    // find optional parameter only used when bulk printing
    const searchParams = useSearchParams()
    const preventResize = !!searchParams?.get('printOnly')

    useWatchVariable(
        ([ref, showLoading]) => {
            if (ref.current) {
                const { chart } = ref.current
                if (showLoading) chart.showLoading()
                else chart.hideLoading()
            }
        },
        [chartRef, isLoading] as const,
        ([refPrev, showLoadingPrev], [refNext, showLoadingNext]) =>
            showLoadingPrev === showLoadingNext && refPrev.current === refNext.current,
        true
    )

    // resive chart when printing
    useEffect(() => {
        // create window resize handler
        const handlePrintResize = (e: Event) => {
            if (chartRef.current?.chart) {
                const scaleToPage = chartRef.current.chart.chartWidth / Math.min((e.target as Window).innerWidth, 1504)
                // scale chart to pixel width of printed page
                // update for system account
                if (!preventResize) {
                    chartRef.current.chart.setSize(1200 * scaleToPage)
                }
            }
        }
        // reset to scale to the container
        const handlePrintEnd = () => {
            chartRef.current?.chart.setSize(null)
        }

        // attach the handlers
        window.addEventListener('beforeprint', handlePrintResize)
        window.addEventListener('afterprint', handlePrintEnd)
        // remove the handlers on dispose
        return () => {
            window.removeEventListener('beforeprint', handlePrintResize)
            window.removeEventListener('afterprint', handlePrintEnd)
        }
    }, [chartRef, preventResize])

    const defaultOptions: Highcharts.Options = merge(
        {
            chart: { style: { fontFamily: 'Roboto,Helvetica,Arial,san-serif', fontSize: '14px' } },
            credits: { enabled: false },
            xAxis: { labels: { style: { fontFamily: 'Roboto,Helvetica,Arial,san-serif', fontSize: '14px' } } },
            yAxis: { labels: { style: { fontFamily: 'Roboto,Helvetica,Arial,san-serif', fontSize: '14px' } } },
            tooltip: { style: { fontFamily: 'Roboto,Helvetica,Arial,san-serif', fontSize: '14px' } },
            accessibility: { enabled: false },
        },
        options
    )
    return (
        <Box sx={{ paddingTop: 2 }}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={defaultOptions} {...rest} />
        </Box>
    )
}

export default Chart
