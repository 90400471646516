import type { SxProps } from '@mui/material/styles'
import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Link from '../Link'
import PermissionFeature from '../wrappers/PermissionFeature'
import { formatDateString } from '../../lib/utils/formatters'
import { TeamAbbrLink, HomeOrAway } from '../layout/header/TeamEntityHeader'

type WatchListPlayerViewNextGameProps = Partial<Pick<DTO.WatchListPlayerInfo, 'nextGameInfo'>> & {
    isWatchListInfoLoading?: boolean
    isolated?: boolean
}

const WatchListPlayerViewNextGame = ({
    isWatchListInfoLoading,
    nextGameInfo,
    isolated,
}: WatchListPlayerViewNextGameProps): JSX.Element => {
    const listSubHeaderStyle: SxProps = {
        display: 'flex',
        alignItems: 'center',
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        paddingLeft: 1,
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'grey.100',
        lineHeight: '36px',
        color: 'text.primary',
        top: isolated ? undefined : { xs: '53px' },
    }

    if (isWatchListInfoLoading)
        return (
            <>
                <ListSubheader sx={listSubHeaderStyle}>Next Game</ListSubheader>
                <ListItem>
                    <Box padding={0}>
                        <Skeleton width={250} />
                    </Box>
                </ListItem>
            </>
        )

    if (!nextGameInfo?.length)
        return (
            <>
                <ListSubheader sx={listSubHeaderStyle}>Next Game</ListSubheader>
                <ListItem>
                    <Typography variant="body2" color="text.secondary">
                        Next Game Info Unavailable
                    </Typography>
                </ListItem>
            </>
        )

    const gamesByDate = nextGameInfo.reduce<Record<string, DTO.WatchListNextGameInfo[]>>((acc, ngi) => {
        const formattedGameDate = formatDateString(ngi.gameDate, 'dddd, MMM DD', 'utc')
        if (!(formattedGameDate in acc)) acc[formattedGameDate] = []
        acc[formattedGameDate].push(ngi)
        return acc
    }, {})
    return (
        <>
            {Object.entries(gamesByDate)
                .sort(([, [{ gameDate: gameDateA }]], [, [{ gameDate: gameDateB }]]) =>
                    gameDateA.localeCompare(gameDateB)
                )
                .map(([gameDate, games]) => (
                    <React.Fragment key={gameDate}>
                        <ListSubheader sx={listSubHeaderStyle}>Next Game: {gameDate}</ListSubheader>
                        {games.map((ngi, idx) => (
                            <ListItem key={ngi.dataSourceId} sx={{ paddingTop: idx > 0 ? 0 : undefined }}>
                                <Stack direction="column">
                                    <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                                        <Box paddingRight={0.5}>
                                            <TeamAbbrLink url={ngi.team.urlSlug} abbr={ngi.team.abbr} isWidget />
                                        </Box>
                                        <Box paddingRight={0.5} color="text.secondary">
                                            <HomeOrAway isHomeTeam={ngi.isHomeTeam} isWidget />
                                        </Box>
                                        <Box paddingRight={0.5}>
                                            <TeamAbbrLink
                                                url={ngi.opponent.urlSlug}
                                                abbr={ngi.opponent.abbr}
                                                isWidget
                                            />
                                        </Box>
                                        {ngi.gameTime && typeof ngi.gameTime === 'string' && (
                                            <Typography variant="body2" color="text.secondary" paddingRight={0.5}>
                                                {formatDateString(ngi.gameTime, 'h:mma z', 'local')} at
                                            </Typography>
                                        )}

                                        {typeof ngi.venue.name === 'string' &&
                                            typeof ngi.venue.city === 'string' &&
                                            typeof ngi.venue.country === 'string' && (
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                    paddingLeft={0}
                                                    marginLeft={0}
                                                >{`${ngi.venue.name}, ${ngi.venue.city}, ${ngi.venue.country}`}</Typography>
                                            )}
                                    </Stack>
                                    <PermissionFeature type="assignment-schedule">
                                        {!!ngi.attendingScouts.length && (
                                            <Typography color="text.secondary" variant="body2">
                                                Attending:{' '}
                                                {ngi.attendingScouts.map((s, i, arr) => (
                                                    <React.Fragment key={s.scoutId}>
                                                        <Link href={`/users/${s.scoutUrlSlug}/feed`} target="_blank">
                                                            {s.scoutName}
                                                        </Link>
                                                        {!(i === arr.length - 1) && <>{', '}</>}
                                                    </React.Fragment>
                                                ))}
                                            </Typography>
                                        )}
                                    </PermissionFeature>
                                </Stack>
                            </ListItem>
                        ))}
                    </React.Fragment>
                ))}
        </>
    )
}

export default WatchListPlayerViewNextGame
