import type { SetOptional } from 'type-fest'
import type TableConfig from '../../lib/types/tableConfigTypes'
import type { TableConfigField } from '../../lib/types/tableConfigTypes'
import React from 'react'
import Box from '@mui/material/Box'
import { formatNumber, isNumber } from '../../lib/utils/math'
import Table from '../Table'

export type StatCategories = {
    tft?: boolean
} & {
    [column in keyof DTO.GameBoxscore]?: boolean
}

export type OptionalGameBoxscore = SetOptional<
    DTO.GameBoxscore,
    'teamId' | 'gameId' | 'gameName' | 'gameScore' | 'gameDate' | 'gameType'
>
type PlayerGameBoxscoreProps = {
    boxscores: OptionalGameBoxscore[] | undefined
    isBoxScoreLoading?: boolean
    hideSorting?: boolean
    greyHeadCells?: boolean
    overrideStatCategories?: (keyof StatCategories)[]
    hideGameColumn?: boolean
    emptyValue?: string
    gameHeader?: string
}

const defaultStatCategories: StatCategories = {
    gameName: true,
    gp: true,
    gs: true,
    min: true,
    dnpReason: true,
    pts: true,
    fgm: true,
    fga: true,
    '3pm': true,
    '3pa': true,
    tft: true,
    fta: true,
    ftm: true,
    drb: true,
    orb: true,
    trb: true,
    ast: true,
    stl: true,
    blk: true,
    to: true,
    fouls: true,
    plusMinus: true,
}

export const boxscoreFields = (
    greyHeadCells?: boolean,
    hideGameColumn?: boolean,
    gameHeader?: string
): TableConfig<OptionalGameBoxscore>['fields'] => {
    const fields: TableConfigField<OptionalGameBoxscore>[] = []
    fields.push(
        {
            key: 'gp',
            label: 'GP',
            select: (x) => (x.gp ? 1 : 0),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
        },
        {
            key: 'gs',
            label: 'GS',
            select: (x) => (x.gs ? 1 : 0),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
        },
        {
            key: 'min',
            label: 'MIN',
            select: (x) => (
                <Box component="span" className="boxscore-min" sx={{ backgroundColor: 'white', position: 'relative' }}>
                    {x.min && x.gp ? formatNumber(x.min, 0) : x.dnpReason || 'DNP'}
                </Box>
            ),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: {
                overflow: 'visible',
                whiteSpace: 'nowrap',
                paddingX: '10px',
                width: 50,
                maxWidth: 50,
                minWidth: 50,
            },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'min',
        },
        {
            key: 'pts',
            label: 'PTS',
            select: (x) => (isNumber(x.pts) ? x.pts : null),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'pts',
        },
        {
            key: 'fgm',
            label: 'FG',
            select: (x) => (isNumber(x.fgm) && isNumber(x.fga) ? `${x.fgm}/${x.fga}` : null),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'fga',
        },
        {
            key: '3pm',
            label: '3P',
            select: (x) => (isNumber(x['3pm']) && isNumber(x['3pa']) ? `${x['3pm']}/${x['3pa']}` : null),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: '3pa',
        },
        {
            key: 'tft',
            label: 'FT',
            select: (x) => (isNumber(x.ftm) && isNumber(x.fta) ? `${x.ftm}/${x.fta}` : null),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'fta',
        },
        {
            key: 'trb',
            label: 'REB',
            select: (x) => (isNumber(x.orb) || isNumber(x.drb) ? (x.orb || 0) + (x.drb || 0) : null),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
        },
        {
            key: 'orb',
            label: 'OREB',
            select: (x) => x.orb,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'orb',
        },
        {
            key: 'drb',
            label: 'DREB',
            select: (x) => x.drb,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'drb',
        },
        {
            key: 'ast',
            label: 'AST',
            select: (x) => x.ast,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'ast',
        },
        {
            key: 'stl',
            label: 'STL',
            select: (x) => x.stl,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'stl',
        },
        {
            key: 'blk',
            label: 'BLK',
            select: (x) => x.blk,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'blk',
        },
        {
            key: 'to',
            label: 'TO',
            select: (x) => x.to,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'to',
        },
        {
            key: 'fouls',
            label: 'PF',
            select: (x) => x.fouls,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'fouls',
        },
        {
            key: 'gameScore',
            label: 'GmSc',
            select: (x) => formatNumber(x.gameScore, 1, 'decimal'),
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'gameScore',
        },
        {
            key: 'plusMinus',
            label: '+/-',
            select: (x) => x.plusMinus,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
            numeric: true,
            skeletonStyle: { xs: 40 },
            sortColumn: 'plusMinus',
        }
    )

    if (!hideGameColumn) {
        fields.unshift({
            key: 'gameName',
            label: gameHeader || 'GAME',
            select: (x) => x.gameName,
            headCellFormat: { paddingY: 0, paddingX: '10px', color: greyHeadCells ? 'text.secondary' : undefined },
            format: { whiteSpace: 'nowrap', paddingX: '10px', width: 140, minWidth: 140 },
            skeletonStyle: { xs: 100 },
        })
    }

    return fields
}

const tableConfig = (
    overrideStatCategories?: (keyof StatCategories)[],
    greyHeadCells?: boolean,
    hideGameColumn?: boolean,
    gameHeader?: string
): TableConfig<OptionalGameBoxscore> => {
    const statCategories: StatCategories = overrideStatCategories
        ? overrideStatCategories.reduce(
              (acc, s) => ({
                  ...acc,
                  [s]: true,
              }),
              {}
          )
        : defaultStatCategories

    const fields = boxscoreFields(greyHeadCells, hideGameColumn, gameHeader).filter(
        ({ key }) => statCategories[key as keyof StatCategories]
    )

    return {
        loadingSkeleton: {
            numOfRows: 1,
            height: 30,
        },
        fields,
    }
}

const PlayerGameBoxscore = ({
    boxscores,
    isBoxScoreLoading,
    hideSorting,
    greyHeadCells,
    overrideStatCategories,
    hideGameColumn,
    emptyValue,
    gameHeader,
}: PlayerGameBoxscoreProps): JSX.Element => (
    <Table<OptionalGameBoxscore>
        rows={boxscores || []}
        config={tableConfig(overrideStatCategories, greyHeadCells, hideGameColumn, gameHeader)}
        isLoading={isBoxScoreLoading}
        hideSorting={hideSorting}
        getRowKey={(r) => r.gameId || r.playerId}
        emptyValue={emptyValue || '-'}
        hover={false}
    />
)

export default PlayerGameBoxscore
