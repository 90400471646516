import type { StatCategories } from '../form/PlayerGameBoxscore'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import IconButton from '@mui/material/IconButton'
import { useSession } from 'next-auth/react'
import Link from '../Link'
import { GameLOC } from '../GameLOC'
import { formatDateString, formatSeason } from '../../lib/utils/formatters'
import { getFormattedNextPrevGames } from '../layout/header/TeamEntityHeader'
import { useWatchListPlayerInfo } from '../../lib/hooks/useUserWatchList'
import { useBreakPoints } from '../../lib/hooks'
import { useConstantsContext } from '../../lib/contexts/ConstantsContext'
import PlayerGameBoxscore from '../form/PlayerGameBoxscore'
import PlayerHeadshotAvatar from '../boards/PositionalBoard/PlayerHeadshotAvatar'
import PermissionFeature from '../wrappers/PermissionFeature'
import WatchListWidgetPlayerViewLoadingSkeleton from './WatchListWidgetPlayerViewLoadingSkeleton'
import WatchListPlayerViewSeasonStats from './WatchListPlayerViewSeasonStats'
import WatchListPlayerViewNextGame from './WatchListPlayerViewNextGame'

export const mapWatchListPrevGameInfoToPrevAndNextGameInfo = (
    info: DTO.WatchListPrevGameInfo
): DTO.PrevAndNextGameInfo => ({
    previousGameResult: {
        gameResult: info.game.result,
        isHomeTeam: info.isHomeTeam,
        homeTeamScore: info.homeTeamScore,
        awayTeamScore: info.awayTeamScore,
        opponentUrlSlug: info.opponent.urlSlug,
        opponentAbbr: info.opponent.abbr || info.opponent.name,
        urlSlug: info.team.urlSlug,
        abbr: info.team.abbr || info.team.name,
        league: info.game.league,
        gameId: info.game.gameId,
    },
    nextGameInfo: null,
})

export const overrideStatCategories: (keyof StatCategories)[] = [
    'min',
    'fgm',
    'fga',
    'trb',
    'orb',
    'drb',
    '3pm',
    '2pa',
    '2pm',
    'tft',
    'ftm',
    'fta',
    'ast',
    'stl',
    'blk',
    'to',
    'fouls',
    'pts',
    'plusMinus',
    'dnpReason',
    'playerId',
]

type PlayerPrevGameProps = {
    playerInfo: WatchListWidgetPlayerViewProps['playerInfo']
    playerView: boolean
    prevGameInfo: DTO.WatchListPrevGameInfo
    showHeadshot?: boolean
    handlePlayerClick?: (playerInfo: WatchListWidgetPlayerViewProps['playerInfo']) => void
    scoutingReports?: {
        author: string
        postId: string
        authorUrlSlug: string
    }[]
    isDesktop: boolean
}

export const PlayerPrevGame = ({
    playerInfo,
    prevGameInfo,
    playerView,
    showHeadshot,
    handlePlayerClick,
    scoutingReports,
    isDesktop,
}: PlayerPrevGameProps): JSX.Element => {
    const { playerName, playerImageUrl, teamLogoUrl } = playerInfo
    const { data: session } = useSession()
    const { boxscore, game } = prevGameInfo
    const gameInfo: DTO.PrevAndNextGameInfo = mapWatchListPrevGameInfoToPrevAndNextGameInfo(prevGameInfo)
    const gameLOCBoxscore = {
        gp: boxscore.gp,
        min: boxscore.min,
        trb: boxscore.trb,
        '3pm': boxscore['3pm'],
        '3pa': boxscore['3pa'],
        '2pm': boxscore['2pm'],
        '2pa': boxscore['2pa'],
        fta: boxscore.fta,
        ast: boxscore.ast,
        stl: boxscore.stl,
        blk: boxscore.blk,
        to: boxscore.to,
        pts: boxscore.pts,
    }

    return (
        <ListItem sx={{ borderBottom: '1px solid', borderColor: 'divider', paddingBottom: '4px' }} component="div">
            <Grid container rowSpacing="5px">
                <Grid item container xs={8}>
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        {!playerView ? (
                            <Stack direction="row" alignItems="center" gap={1}>
                                {showHeadshot && (
                                    <PlayerHeadshotAvatar
                                        outlined
                                        imageUrl={playerImageUrl}
                                        teamImageUrl={teamLogoUrl}
                                    />
                                )}
                                <Box>
                                    <Typography fontWeight="medium" lineHeight={1.1}>
                                        {playerName}
                                    </Typography>
                                    <Stack display={{ xs: 'none', xl: 'flex' }} direction="row">
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            marginRight="4px"
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            {`${formatDateString(game.date, 'MMM D', 'utc')}:`}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: 'flex',
                                                    gap: '4px',
                                                    alignItems: 'baseline',
                                                },
                                                color: 'text.secondary',
                                            }}
                                            marginRight="4px"
                                        >
                                            {getFormattedNextPrevGames({
                                                gameInfo,
                                                hasNBAStatsPermission:
                                                    !!session?.roles.featurePermissions['nba-team-stats'],
                                                hasGLGStatsPermission:
                                                    !!session?.roles.featurePermissions['glg-team-stats'],
                                                isWidget: true,
                                            })}
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        ) : (
                            <Stack direction="row">
                                <Typography
                                    color="text.primary"
                                    variant="body2"
                                    marginRight="4px"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {`${formatDateString(game.date, 'MMM DD', 'utc')}:`}
                                </Typography>
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'flex',
                                            gap: '4px',
                                            alignItems: 'baseline',
                                        },
                                        color: 'text.secondary',
                                    }}
                                    marginRight="4px"
                                >
                                    {getFormattedNextPrevGames({
                                        gameInfo,
                                        hasNBAStatsPermission: !!session?.roles.featurePermissions['nba-team-stats'],
                                        hasGLGStatsPermission: !!session?.roles.featurePermissions['glg-team-stats'],
                                        isWidget: true,
                                    })}
                                </Box>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={4} justifyContent="end" alignItems="start" display="flex">
                    <GameLOC boxscore={gameLOCBoxscore} league={game.league} />
                </Grid>
                {!playerView && (
                    <Grid display={{ xs: 'flex', xl: 'none' }} item xs={12}>
                        <Stack direction="row">
                            <Typography
                                color="text.primary"
                                variant="body2"
                                marginRight="4px"
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                {`${formatDateString(game.date, 'MMM DD', 'utc')}:`}
                            </Typography>
                            <Box
                                sx={{
                                    display: {
                                        xs: 'flex',
                                        gap: '4px',
                                        alignItems: 'baseline',
                                    },
                                    color: 'text.secondary',
                                }}
                                marginRight="4px"
                            >
                                {getFormattedNextPrevGames({
                                    gameInfo,
                                    hasNBAStatsPermission: !!session?.roles.featurePermissions['nba-team-stats'],
                                    hasGLGStatsPermission: !!session?.roles.featurePermissions['glg-team-stats'],

                                    isWidget: true,
                                })}
                            </Box>
                        </Stack>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <PlayerGameBoxscore
                        boxscores={[{ ...boxscore, playerHeadshotUrl: '', playerName: '', playerUrlSlug: '' }]}
                        overrideStatCategories={overrideStatCategories}
                        isBoxScoreLoading={false}
                        hideSorting
                        greyHeadCells
                    />
                </Grid>
                <Grid item container xs={12} justifyContent="space-between" alignItems="flex-start">
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <PermissionFeature type="post-feed">
                                {scoutingReports?.map((r) => (
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }} key={r.postId}>
                                        <Link key={r.postId} href={`/posts/${r.postId}`} target="_blank">
                                            <Button
                                                variant="text"
                                                sx={{ textTransform: 'none', paddingX: '2px', paddingY: '1px' }}
                                                endIcon={<OpenInNewIcon />}
                                                size="small"
                                            >
                                                {scoutingReports.length > 1 && !isDesktop
                                                    ? 'Scout. Report'
                                                    : 'Scouting Report'}
                                            </Button>
                                        </Link>

                                        <Typography
                                            component="span"
                                            variant="button"
                                            textTransform="none"
                                            fontWeight="normal"
                                            fontSize="12px"
                                        >
                                            ({r.author})
                                        </Typography>
                                    </Box>
                                ))}
                            </PermissionFeature>
                        </Box>
                        {!playerView && (
                            <Button
                                sx={{ marginLeft: 'auto', textTransform: 'none', paddingX: '2px', paddingY: '1px' }}
                                onClick={() => (handlePlayerClick ? handlePlayerClick(playerInfo) : {})}
                                variant="text"
                                endIcon={<ArrowForwardIcon />}
                                size="small"
                            >
                                Player Details
                            </Button>
                        )}
                    </>
                </Grid>
            </Grid>
        </ListItem>
    )
}

type BackIconPressType = () => void
export type WatchListWidgetPlayerViewProps = {
    playerInfo: Pick<
        DTO.UserWatchListInfo,
        'playerName' | 'playerUrlSlug' | 'playerId' | 'playerImageUrl' | 'teamLogoUrl'
    >
} & (
    | { isolated: true; handleBackIconPress?: BackIconPressType }
    | { isolated?: false; handleBackIconPress: BackIconPressType }
)

const WatchListWidgetPlayerView = ({
    playerInfo,
    handleBackIconPress,
    isolated,
}: WatchListWidgetPlayerViewProps): JSX.Element => {
    const { statsYear } = useConstantsContext()
    const { isDesktop } = useBreakPoints()
    const { playerName, playerUrlSlug } = playerInfo
    const { data: watchListInfo, isLoading: isWatchListInfoLoading } = useWatchListPlayerInfo(
        playerInfo.playerId,
        statsYear
    )

    return (
        <List sx={{ height: '100%', overflow: 'auto', paddingTop: 0 }}>
            {!isolated && (
                <ListSubheader
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderTopLeftRadius: 1,
                        borderTopRightRadius: 1,
                        padding: 1,
                        paddingTop: '9px',
                        paddingBottom: '9px',
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} gap={0.5}>
                        <Button
                            onClick={handleBackIconPress}
                            variant="text"
                            startIcon={<ArrowBackIcon />}
                            size="small"
                            sx={{ lineHeight: 1.5, display: { xs: 'none', xl: 'flex' } }}
                        >
                            Watchlist
                        </Button>
                        <IconButton
                            color="primary"
                            sx={{ lineHeight: 1.5, display: { xs: 'flex', xl: 'none' } }}
                            onClick={handleBackIconPress}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <PlayerHeadshotAvatar
                                outlined
                                imageUrl={playerInfo.playerImageUrl}
                                height={35}
                                width={35}
                            />
                            <Typography color="text.primary" fontWeight="medium">
                                {playerName}
                            </Typography>
                        </Stack>

                        <Link href={`/players/${playerUrlSlug}/feed`} target="_blank">
                            <Button
                                variant="text"
                                endIcon={<OpenInNewIcon />}
                                size="small"
                                sx={{ lineHeight: 1.5, display: { xs: 'none', xl: 'flex' } }}
                            >
                                View Player
                            </Button>
                            <IconButton
                                color="primary"
                                size="small"
                                sx={{ lineHeight: 1.5, display: { xs: 'flex', xl: 'none' } }}
                                onClick={handleBackIconPress}
                            >
                                <OpenInNewIcon />
                            </IconButton>
                        </Link>
                    </Stack>
                </ListSubheader>
            )}
            <WatchListPlayerViewNextGame
                isWatchListInfoLoading={isWatchListInfoLoading}
                nextGameInfo={watchListInfo?.nextGameInfo}
                isolated={isolated}
            />
            <ListSubheader
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 1,
                    paddingLeft: 1,
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: 'grey.100',
                    lineHeight: '36px',
                    color: 'text.primary',
                    top: isolated ? undefined : { xs: '53px' },
                }}
            >
                {`${formatSeason(statsYear)} Season`}
            </ListSubheader>
            <WatchListPlayerViewSeasonStats
                isWatchListInfoLoading={isWatchListInfoLoading}
                seasonStats={watchListInfo?.seasonStats}
                currentTeamInfo={watchListInfo?.currentTeamInfo}
            />
            <ListSubheader
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 1,
                    paddingLeft: 1,
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: 'grey.100',
                    lineHeight: '36px',
                    color: 'text.primary',
                    top: isolated ? undefined : { xs: '53px' },
                }}
            >
                Previous Games
            </ListSubheader>
            {isWatchListInfoLoading && (
                <Grid container paddingTop={1} paddingX={2} rowSpacing={1}>
                    <Grid item xs={12}>
                        <WatchListWidgetPlayerViewLoadingSkeleton type="game" />
                    </Grid>
                    {isolated && (
                        <Grid item xs={12}>
                            <WatchListWidgetPlayerViewLoadingSkeleton type="game" />
                        </Grid>
                    )}
                    {isolated && (
                        <Grid item xs={12}>
                            <WatchListWidgetPlayerViewLoadingSkeleton type="game" />
                        </Grid>
                    )}
                </Grid>
            )}
            {watchListInfo?.prevGamesInfo.map((g) => (
                <Box key={g.game.gameId}>
                    <PlayerPrevGame
                        prevGameInfo={g}
                        playerInfo={playerInfo}
                        scoutingReports={g.scoutingReports}
                        isDesktop={isDesktop}
                        playerView
                    />
                </Box>
            ))}
        </List>
    )
}

export default WatchListWidgetPlayerView
