import type { GameLOCBoxscore } from '../lib/utils/stats'
import React, { useMemo } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useBreakPoints, useLOCValues } from '../lib/hooks'
import { asNumber, formatNumber } from '../lib/utils/math'
import { calculateGameLOC } from '../lib/utils/stats'
import { getMatchingLOCFromValue } from './profile/LOCProjections'

type GameLOCProps = {
    boxscore: GameLOCBoxscore & { gp?: boolean }
    league: Enum.League
    text?: string
}

export const GameLOC = ({ boxscore, league, text }: GameLOCProps): JSX.Element => {
    const { isLoading: isLOCValuesLoading, data: locValues } = useLOCValues()
    const { isMobile } = useBreakPoints()
    const defaultText = isMobile ? 'Gm.' : 'Game'

    const [locLetter, locNumStr] = useMemo<[] | [string, string]>(() => {
        if (boxscore.gp === false) return []

        const isProLOC = league === 'NBA' || league === 'G-League' || league === 'Summer'
        const gameLOC = calculateGameLOC(isProLOC ? 'PRO' : 'AM', boxscore)
        const l = asNumber(formatNumber(gameLOC))

        const val = l !== null && l >= 0 && getMatchingLOCFromValue(l, locValues)
        if (!val) return []

        const locDiff = l >= 8 ? 0 : l - val.value
        const locNum = `${locDiff >= 0 ? '+' : ''}${formatNumber(locDiff) || ''}`
        return val.abbr ? [val.abbr, locNum] : []
    }, [locValues, boxscore, league])

    return locLetter && !isLOCValuesLoading ? (
        <Paper
            sx={({ spacing, palette }) => ({
                backgroundColor: palette.callOut.info,
                padding: { xs: spacing(0.5, 0.5), sm: spacing(0.5, 1.0) },
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: { xs: 72, sm: 100 },
                justifyContent: 'center',
            })}
            elevation={0}
        >
            <Box display="flex" flexDirection="row" alignItems="center" whiteSpace="nowrap">
                <Typography variant="caption" sx={{ maxWidth: 65, lineHeight: 1.17, marginRight: 1 }}>
                    {text || defaultText}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                    {locLetter}
                </Typography>
                {!!locNumStr && (
                    <Typography variant="caption" paddingLeft={0.5} sx={{ lineHeight: 1.17 }}>
                        {locNumStr}
                    </Typography>
                )}
            </Box>
        </Paper>
    ) : (
        <>{}</>
    )
}

export default GameLOC
